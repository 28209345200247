import * as React from 'react'
import SuccessPage from '/src/templates/SuccessPage'

const Page = ({ postInfo }) => {
    const post = {
        title: 'Submission Success',
        indexStatus: 'noindex',
        followStatus: 'nofollow'
    }

    return <SuccessPage postInfo={postInfo} post={post} />
}

export default Page

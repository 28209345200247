const faq = [
    {
        title: 'What is Life Insurance?',
        content: `<p>Life insurance is a way to provide for your family once you’re gone. It’s a difficult thing to think about, because no-one wants to spend their time imagining how or when their life might end and the financial repercussions this would have on their loved ones.</p>
        
        <p>Life insurance is often thought of as a complicated product, this doesn’t have to be the case. The right policy for you is determined by a variety of factors including your health and lifestyle, age, whether you have children or grandchildren you wish to leave a lump sum to, or perhaps have a mortgage to pay off.</p>
        
        <p>Life insurance comes in many shapes and sizes, and the language and misconceptions surrounding it can make it a tricky subject to approach. Many people worry that life insurance is a dangerous financial product and rarely pays out but, on average, 98% of all life insurance claims made in the UK are paid out – in 2019 this averaged at £15.8m every day.</p>
        
        <p>From ABI.org.uk, May 2020</p>`
    },
    {
        title: 'Why do I need a life insurance policy?',
        content: `<p>We understand that life insurance isn’t something that people tend to think about often, because it’s not a comfortable subject to dwell on. It’s difficult to focus on paying for a product that you won’t see the benefit of… but your loved ones will. Sometimes, we have to take a look at the hard questions to understand why life insurance is so important and why everyone should consider a life insurance policy.</p>

        <p>What would happen if you passed away tomorrow? Are your family financially prepared should that happen? Are there any arrangements in place to cover outstanding debts like mortgages? Who is going to pay for the funeral and associated costs? Whilst these questions are tough to think about, life insurance exists to answer these questions and provide you with peace of mind so you can focus on the things that matter – spending time with your family today, without having to worry about tomorrow.</p>`
    },
    {
        title: 'How does life insurance work?',
        content: `<p>Because life insurance can come in many different shapes and sizes, this can depend on your personal circumstances and what works best for you. As an example, a product you could consider is ‘Level Term’ life insurance. This type of life insurance provides a fixed sum assured – the money you’ve decided should be available when you die – to go to your loved ones or nominated beneficiaries. You choose how much this could be and how long the policy lasts for. All you do is keep making your monthly premium payments and the cover is in place.</p>

        <p>It's not always easy figuring out how much cover you need and how long you'll need it for – that's why our team of dedicated brokers get to know you and your personal circumstances before presenting you with the facts. We're a non-advisory service so we only provide you with the facts, not our opinions.</p>`
    }
]

export default faq

const faqOne = [
    {
        title: 'Who is Protect Line?',
        content: `<p><a href="https://www.protectline.co.uk">Protect Line</a> is one of the UK's leading fee-free Life Insurance Brokers. With over 35,000 five star reviews on Trustpilot and multiple consumer awards, they're also the most trusted.</p>
        
        <p>A true family business, Protect Line was established in 2010 by a husband and wife team. The business has grown with pure passion and entrepreneurial drive, today employing over 250 staff across offices in Bournemouth and London.</p>
        
        <p>Protect Line sincerely believe that every person deserves to enjoy life with their loved ones and have the peace of mind that comes with financial security. They know the importance of Life Insurance and are proud of having protected over 270,000 families from financial hardship if the unexpected happens. This is why it is their company mission to protect as many UK families as possible.</p>`
    },
    {
        title: 'What happens next?',
        content: `<p>We're going to be calling you shortly to get you your free quote! Unlike car and home insurance, life insurance can sometimes get a little complicated when factoring in your health and lifestyle - that's why it's always better to speak to a real person than take your chances with a form online.</p>

        <p>When you also consider that we're dealing in massive sums of money, you want to make sure that everything is 100% correct and that, ultimately, your family are protected properly. That's why all of our calls are recorded for training and monitoring purposes and to also help if a dispute arises in the future.</p>
        
        <p>We also believe that speaking with a genuine person ensures that we fully understand your needs and requirements and what you want your policy to do for you and your family. This makes sure you have the right cover in place so you have peace of mind knowing everything is done and dusted.</p>`
    },
    {
        title: 'How much does life insurance cost?',
        content: `<p>Your life insurance could cost the same each month as a couple of take-away coffees. Usually a policy will have guaranteed fixed premiums throughout the policy term - our friendly team will fill you in on all the details during your chat.</p>

        <p>It's free to use our service to get your life insurance - if you decide to take out a policy with one of our providers, we'll be paid a commission - there is no fee to you.</p>
        
        <p>If you've already got cover or seen quotes elsewhere, let us know! We will always try to find you a competitive quote at a price to suit your needs.</p>`
    },
    {
        title: `Will you sell me something I don't want?`,
        content: `<p>No, Protect Line is an FCA regulated non-advisory service, which means we won't give our opinions on your cover or push you in a certain direction. Instead, you'll only be given honest & impartial information - in plain English - so you can make the important decisions yourself. It's always your choice which quotes you accept or decline.</p>`
    }
]

export default faqOne
